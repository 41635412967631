import { AnalyticsEventPropTypes } from '@hpx-core-experiences/react-my-account-commons/dist';
import {
  IconLockAccount,
  IconPrinterInkDrop,
  IconRearrange,
  IconShoppingCart,
  IconTruck
} from '@veneer/core';
import React from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import {
  AddPrinterHyperlinkClicked,
  ChangeHpAllInPlanHyperlinkClicked,
  ChangeInstantInkPlanHyperlinkClicked,
  ChangePasswordHyperlinkClicked,
  ChangePasswordOnPortalHyperlinkClicked,
  ContactHpAllInProLiveSupportHyperlinkClicked,
  GetProductSupportHyperlinkClicked,
  PrinterShowsAccountIssueHyperlinkClicked,
  publishEvent,
  RegisterDeviceHyperlinkClicked,
  RequestMoreInkHyperlinkClicked,
  ShowHpAllInCardModuleDisplayed,
  ShowInstantInkCardModuleDisplayed,
  StartReturnHyperlinkClicked,
  UpdateHpAllInPaymentMethodHyperlinkClicked,
  UpdateInstantInkPaymentMethodHyperlinkClicked,
  UpdatePersonalDetailsHyperlinkClicked,
  ViewHpAllInPrintUsageHyperlinkClicked,
  ViewInstantInkPrintUsageHyperlinkClicked,
  ViewOrderHistoryHyperlinkClicked
} from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';
import FrameCard, { IFrameCardProps } from './FrameCardTemplate';
import { Frame } from './styles';
import useFeatureFlags, { FeatureFlagsEnum } from 'src/hooks/useFeatureFlags';

interface CardsProps {
  subscriptions: {
    hasActiveInstantInk: boolean;
    hasActiveHpAllIn: boolean;
    total: number;
    totalHpAllIn: number;
    totalInstantInk: number;
  } | null;
  subscriptionsLoading: boolean;
}

const FrameCards = ({ subscriptions, subscriptionsLoading }: CardsProps) => {
  const { translate } = useDependencyManagerContext();

  const {
    featureFlagValue: flagChangePassword,
    isLoading: isLoadingFlagChangePassword
  } = useFeatureFlags(FeatureFlagsEnum.securityHPIDPassword);

  const {
    featureFlagValue: flagUpdatePersonalDetails,
    isLoading: isLoadingFlagUpdatePersonalDetails
  } = useFeatureFlags(FeatureFlagsEnum.profileListPersonalDetails);

  const Cards: IFrameCardProps[] = [
    {
      title: translate('home.cards.loginAndSecurity.title', 'Login & security'),
      urls: [
        {
          link:
            flagChangePassword && !isLoadingFlagChangePassword
              ? URLS.Cards.LoginAndSecurity.ChangePasswordOnPortal
              : URLS.Cards.LoginAndSecurity.ChangePassword,
          text: translate(
            'home.cards.loginAndSecurity.changePassword',
            'Change password'
          ),
          analyticsEvent:
            flagChangePassword && !isLoadingFlagChangePassword
              ? ChangePasswordOnPortalHyperlinkClicked
              : ChangePasswordHyperlinkClicked,
          isInternalLink: flagChangePassword && !isLoadingFlagChangePassword
        },
        {
          link:
            flagUpdatePersonalDetails && !isLoadingFlagUpdatePersonalDetails
              ? URLS.Cards.LoginAndSecurity.UpdatePersonalDetailsOnPortal
              : URLS.Cards.LoginAndSecurity.UpdatePersonalDetails,
          text: translate(
            'home.cards.loginAndSecurity.updatePersonalDetails',
            'Update personal details'
          ),
          analyticsEvent: UpdatePersonalDetailsHyperlinkClicked,
          isInternalLink:
            flagUpdatePersonalDetails && !isLoadingFlagUpdatePersonalDetails
        }
      ],
      icon: <IconLockAccount size={30} />
    },
    {
      title: translate('home.cards.yourPurchases.title', 'Your purchases'),
      urls: [
        {
          link: URLS.Cards.YourPurchases.ViewOrderHistory,
          text: translate(
            'home.cards.yourPurchases.viewOrderHistory',
            'View order history'
          ),
          analyticsEvent: ViewOrderHistoryHyperlinkClicked
        },
        {
          link: URLS.Cards.YourPurchases.StartReturn,
          text: translate(
            'home.cards.yourPurchases.startReturn',
            'Start a return'
          ),
          analyticsEvent: StartReturnHyperlinkClicked
        }
      ],
      icon: <IconShoppingCart size={30} />
    },
    {
      title: translate('home.cards.otherLinks.title', 'Other links'),
      urls: [
        {
          link: URLS.Cards.OtherLinks.PrinterShowsAccountIssue,
          text: translate(
            'home.cards.otherLinks.printerShowsAccountIssue',
            'Printer shows account issue'
          ),
          analyticsEvent: PrinterShowsAccountIssueHyperlinkClicked
        },
        {
          link: URLS.Cards.OtherLinks.AddPrinter,
          text: translate(
            'home.cards.otherLinks.addPrinterToAccount',
            'Add a printer to your account'
          ),
          analyticsEvent: AddPrinterHyperlinkClicked
        },
        {
          link: URLS.Cards.OtherLinks.GetProductSupport,
          text: translate(
            'home.cards.otherLinks.getProductSupport',
            'Get product support'
          ),
          analyticsEvent: GetProductSupportHyperlinkClicked
        },
        {
          link: URLS.Cards.OtherLinks.RegisterDevice,
          text: translate(
            'home.cards.otherLinks.registerDevice',
            'Register a device'
          ),
          analyticsEvent: RegisterDeviceHyperlinkClicked
        }
      ],
      icon: <IconRearrange size={30} />
    }
  ];

  const instantInkCard = {
    title: translate('common.instantInk', 'HP Instant Ink'),
    icon: <IconTruck size={30} />,
    urls: [
      {
        link: URLS.Cards.InstantInk.UpdatePaymentMethod,
        text: translate(
          'home.cards.updatePaymentMethod',
          'Update payment method'
        ),
        analyticsEvent: UpdateInstantInkPaymentMethodHyperlinkClicked
      },
      {
        link: URLS.Cards.InstantInk.ChangePauseOrCancelPlan,
        text: translate(
          'home.cards.hpInstantInk.changePauseOrCancelPlan',
          'Change, pause, or cancel plan'
        ),
        analyticsEvent: ChangeInstantInkPlanHyperlinkClicked
      },
      {
        link: URLS.Cards.InstantInk.ViewPrintUsage,
        text: translate('home.cards.viewPrintUsage', 'View print usage'),
        analyticsEvent: ViewInstantInkPrintUsageHyperlinkClicked
      },
      {
        link: URLS.Cards.InstantInk.RequestMoreInk,
        text: translate(
          'home.cards.hpInstantInk.requestMoreInk',
          'Request more ink'
        ),
        analyticsEvent: RequestMoreInkHyperlinkClicked
      }
    ]
  };

  const hpAllInCard = {
    title: translate('common.allIn', 'HP All-In Plan'),
    urls: [
      {
        link: URLS.Cards.HpAllIn.UpdatePaymentMethod,
        text: translate(
          'home.cards.updatePaymentMethod',
          'Update payment method'
        ),
        analyticsEvent: UpdateHpAllInPaymentMethodHyperlinkClicked
      },
      {
        link: URLS.Cards.HpAllIn.ChangeOrCancelPlan,
        text: translate(
          'home.cards.hpAllIn.changeOrCancelPlan',
          'Change or cancel plan'
        ),
        analyticsEvent: ChangeHpAllInPlanHyperlinkClicked
      },
      {
        link: URLS.Cards.HpAllIn.ViewPrintUsage,
        text: translate('home.cards.viewPrinterUsage', 'View print usage'),
        analyticsEvent: ViewHpAllInPrintUsageHyperlinkClicked
      },
      {
        link: URLS.Cards.HpAllIn.ContactProLiveSupport,
        text: translate(
          'home.cards.hpAllIn.contactProLiveSupport',
          'Contact 24/7 Pro live support'
        ),
        analyticsEvent: ContactHpAllInProLiveSupportHyperlinkClicked
      }
    ],
    icon: <IconPrinterInkDrop size={30} />
  };

  const analyticsEvents: AnalyticsEventPropTypes[] = [];

  if (subscriptionsLoading) {
    Cards.push({
      loading: true
    });
  } else {
    if (subscriptions?.hasActiveInstantInk === true) {
      analyticsEvents.push(
        ShowInstantInkCardModuleDisplayed(
          subscriptions.total,
          subscriptions.totalInstantInk,
          subscriptions.totalHpAllIn
        )
      );
      Cards.push(instantInkCard);
    }

    if (subscriptions?.hasActiveHpAllIn === true) {
      analyticsEvents.push(
        ShowHpAllInCardModuleDisplayed(
          subscriptions.total,
          subscriptions.totalInstantInk,
          subscriptions.totalHpAllIn
        )
      );
      Cards.push(hpAllInCard);
    }

    analyticsEvents.length > 0 && publishEvent(analyticsEvents);
  }

  return (
    <Frame data-testid="frame-cards">
      {Cards.map((card, i) => (
        <FrameCard
          key={card.title || i}
          card={card}
        />
      ))}
    </Frame>
  );
};

export default FrameCards;
