import React, { useEffect } from 'react';
import { createGraphQLClient, Platform, Stack } from '@clientos/graphql-client';
import { createGraphQLProvider } from '@clientos/graphql-client/dist/react';
import { DependencyManagerProvider } from 'src/contexts/dependencyManager';
import * as T from './types';
import Home from 'src/pages/Home';
import { HomeScreenDisplayed, publishEvent } from 'src/utils/analytics';
import * as S from './styles';

// This file is your main component, it's the one that will be rendered by default.
// We recommend to create small components inside the components folder
// and import them here based on your need to make this file easier to understand.
const AppComponent: React.FC<T.AppPropsType> = () => {
  return (
    <S.Container data-testid="home-page">
      <Home />
    </S.Container>
  );
};

// This component wraps your main component with the DependencyManagerProvider
// This provider is responsible to manage global dependencies to your component
const App: React.FC<T.AppPropsType> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const stack: Stack = (window as any).Shell?.v1.app.getAuthStack();
  const client = createGraphQLClient({
    authTokenCallback: async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return await (props.authProvider as any).getAccessToken();
    },
    platform: Platform.WEB,
    stack: stack,
    isShellPortal: true
  });

  const GraphQLProvider = createGraphQLProvider(React);

  useEffect(() => {
    publishEvent([HomeScreenDisplayed]);
  }, []);

  return (
    <DependencyManagerProvider>
      <GraphQLProvider
        client={client}
        // eslint-disable-next-line react/no-children-prop
        children={<AppComponent {...props} />}
      />
    </DependencyManagerProvider>
  );
};

export default App;
