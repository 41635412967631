import { Card } from '@veneer/core';
import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  display: inline-block;
  padding: ${tokens.layout.size4};
  min-width: min-content;
  width: auto;

  @media (min-width: 1400px) {
    min-width: min-content;
    white-space: break-spaces;
  }

  @media (max-width: ${tokens.layout.smMax}) {
    width: 100%;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${tokens.layout.size2} 0 ${tokens.layout.size2} 0;
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: space-between;

  a {
    color: #0073a8;
    &:hover,
    &:focus {
      text-decoration: underline;
      color: #0073a8;
    }
  }
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${tokens.layout.size8} ${tokens.layout.size8} ${tokens.layout.size8}
    ${tokens.layout.size8};
`;

export const Link = styled.a`
  width: fit-content;
  cursor: pointer;
`;
