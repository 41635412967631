import { useEffect, useState } from 'react';
import { interfaces } from '@jarvis/shell-commons';

const launchDarklyClientKey = 'myaccount';

export enum FeatureFlagsEnum {
  qualtricsSurvey = 'qualtrics-survey',
  welcomeNonUsUsers = 'welcome-non-us-users',
  originalFeedbackExperience = 'original-feedback-experience',
  securityHPIDPassword = 'security-hp-id-password',
  profileListPersonalDetails = 'profile-list-personal-details'
}

const featureFlagsDefaultValues = {
  qualtricsSurvey: false,
  welcomeNonUsUsers: false,
  originalFeedbackExperience: false,
  securityHPIDPassword: false,
  profileListPersonalDetails: false
} as any;

const useFeatureFlags = (key: string) => {
  const getClient = interfaces?.v1?.featureFlags?.getClient;
  const defaultValue = featureFlagsDefaultValues[key] || false;
  const [featureFlagValue, setFeatureFlagValue] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getFeatureFlagValue = async () => {
      const client = await getClient(launchDarklyClientKey);
      const featureFlagValue = await client?.getFeatureFlag({
        key,
        defaultValue
      });

      setFeatureFlagValue(featureFlagValue);
      setIsLoading(false);
    };

    getFeatureFlagValue();
  }, [key, getClient, defaultValue, setFeatureFlagValue]);

  return { featureFlagValue, isLoading };
};

export default useFeatureFlags;
